import { color, Icon, media, Modal, useI18n } from "@sencrop/ui-components";
import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { ImageHeroContainer } from "./ImageHeroContainer";
import VideoPortal from "./VideoPortal";

type VideoHeroContainerProps = {
  videoUrl: string;
  $centeredOnMobile?: boolean;
  children: ReactNode;
};

const VideoHeroContainer = ({
  videoUrl,
  $centeredOnMobile,
  children,
}: VideoHeroContainerProps) => {
  const [opened, setOpened] = useState(false);
  const { t } = useI18n();

  return (
    <>
      <ImageHeroContainer $centeredOnMobile={$centeredOnMobile || false}>
        {children}
        <ZoomInHover>
          <PlayIcon
            aria-label={t("video.play_button.aria_label")}
            onClick={() => setOpened(true)}
            tabIndex={0}
            onKeyDown={() => undefined}
          >
            <Icon color="light" size={36} name="player-play" />
          </PlayIcon>
        </ZoomInHover>
      </ImageHeroContainer>
      <VideoPortal>
        <Overlay>
          <Modal
            width={1136}
            opened={opened}
            onClose={() => setOpened(false)}
            style={{ minHeight: "initial", maxHeight: "calc(100vh - 2rem)" }}
          >
            <CloseButton
              onClick={() => setOpened(false)}
              role="button"
              tabIndex={0}
              aria-label={t("pop_in.close.aria_label")}
            >
              <Icon color="light" size={32} name="close-small" />
            </CloseButton>
            <CloseBanner />
            <VideoWrapper>
              <iframe
                src={videoUrl + "?autoplay=1"}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                // @ts-ignore: this doesn't exists from the type ?
                webkitAllowFullScreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
            </VideoWrapper>
          </Modal>
        </Overlay>
      </VideoPortal>
    </>
  );
};
export default VideoHeroContainer;

const ZoomInHover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  > *:first-child {
    transform: scale(1);
    transition: transform 3s cubic-bezier(0.23, 1, 0.32, 1), opacity 600ms;
  }
  &:hover {
    > *:first-child {
      transform: scale(1.1);
    }
  }
`;

const PlayIcon = styled.button`
  cursor: pointer;
  position: absolute;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: ${color("branding-primary")};
  opacity: 0.8;
  left: 50%;
  ${media.greaterThan("tablet")`
    left: 75%;
  `}
  margin-left: -2.5rem;
  top: 50%;
  margin-top: -2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    height: auto;
  }
`;
const Overlay = styled.div`
  * {
    overflow: visible !important;
  }
  > div {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
`;

const CloseBanner = styled.div`
  height: 3rem;
  width: 100%;
  background: black;
  ${media.greaterThan("tablet")`
    display: none;
  `}
`;

const VideoWrapper = styled.div`
  max-width: 1136px;
  position: relative;
  width: 100%;
  height: calc(100% - 3rem);
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  ${media.greaterThan("tablet")`
    padding-bottom: min(56.25%, calc(100vh - 2rem)); /* 16:9 */
    height: 0;
    iframe {
      border-radius: 6px;
    }
  `}
`;

const CloseButton = styled.button`
  z-index: 2000;
  cursor: pointer;
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.8;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.greaterThan("tablet")`
    background-color: ${color("action-soft:75")};
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: ${color("action-soft:90")};
    }
    top: -1.5rem;
    right: -1.5rem;
  `}
`;
