import { media } from "@sencrop/ui-components";
import React from "react";
import styled from "styled-components";

type ImageHeroContainerProps = {
  $centeredOnMobile: boolean;
};

export const ImageHeroContainer = styled.div<ImageHeroContainerProps>`
  margin-top: -6rem;
  height: 100%;
  * {
    height: 100%;
  }
  img {
    transform: scale(1.2) translateY(10%) !important;
    object-position: ${(props) => (props.$centeredOnMobile ? "55%" : "75%")}
      center !important;
  }
  ${media.greaterThan("tablet")`
      * {
        overflow: visible !important;
      }
      [data-gatsby-image-wrapper] {
        pointer-events: none;
      }
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      margin-top: 0;
      img {
        transform: translateX(20%) !important;
        object-position: right bottom !important;
        object-fit: contain !important;
      }
   `};
`;
