import { color, Icon, media, useBreakpoint } from "@sencrop/ui-components";
import { WebText, webtext, WebTitle } from "@sencrop/ui-web";
import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import ButtonElement from "../containers/ButtonElement";
import ImageElement from "../components/ImageElement";
import PriceElement from "../components/PriceElement";
import { containerCss } from "../helpers/style";
import { InlineContainer } from "./SectionBottom";
import BackgroundImage from "../images/hero/bg-img-header-home.jpg";
import BackgroundImageMobile from "../images/hero/hero-background-wave1-mobile.png";
import { ImageHeroContainer } from "../components/ImageHeroContainer";
import VideoHeroContainer from "../components/VideoHeroContainer";
import LinkRenderer from "../containers/LinkRenderer";

function getButtonColor(buttonColor?: string) {
  switch (buttonColor) {
    case "blue":
      return `secondary`;
    case "green":
      return `primary`;
    default:
      return `primary`;
  }
}

const SectionHeroImage = ({
  backgroundColor,
  titleColor,
  image,
  sectionTitle,
  description,
  button,
  textArguments: _arguments,
  price,
  textBeforePrice,
  buttonColor,
  argumentsLayout,
  superpositionOfNextSection,
  centeredImageOnMobile,
  showPrice,
  videoUrl,
  secondaryButton,
  secondaryButtonColor,
  carrouselImages,
  carrouselImagesSubtexts,
  carrouselSecondaryImages,
  carrouselLinks,
  carrouselIcons,
  extraSpaceOnTop,
  extraPaddingBottom,
}: GatsbyTypes.SectionHeroImageFragmentFragment) => {
  const color = getButtonColor(buttonColor);
  const displayPrice = showPrice ?? true;
  const onMobileOrTablet = useBreakpoint("mobile", "tablet");

  const [carouselIndex, setCarouselIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const MIN_SWIPE_DISTANCE = 50;
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    // Store the initial touch position
    if (carouselRef.current) {
      const touchStartX = e.touches[0]?.clientX;
      carouselRef.current.dataset.touchStartX = touchStartX.toString();
    }
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (carouselRef?.current?.dataset.touchStartX) {
      const touchEndX = e.changedTouches[0].clientX;
      const touchStartX = parseFloat(carouselRef.current.dataset.touchStartX);
      const swipeDistance = touchEndX - touchStartX;

      // Determine swipe direction
      if (swipeDistance > MIN_SWIPE_DISTANCE) {
        // Swipe right, call goToPrevSlide()
        goToPrevSlide();
      } else if (swipeDistance < -MIN_SWIPE_DISTANCE) {
        // Swipe left, call goToNextSlide()
        goToNextSlide();
      }

      // Reset touch start position
      if (carouselRef.current) {
        carouselRef.current.dataset.touchStartX = undefined;
      }
    }
  };

  const goToNextSlide = () => {
    setCarouselIndex((prevIndex) => (prevIndex + 1) % carrouselImages?.length);
  };

  const goToPrevSlide = () => {
    setCarouselIndex(
      (prevIndex) =>
        (prevIndex - 1 + carrouselImages?.length) % carrouselImages?.length
    );
  };

  useEffect(() => {
    // trigger the carousel if exists, and reset timer if already been triggered in the last 5 seconds
    if (carrouselImages) {
      const intervalId = setInterval(goToNextSlide, 6000);

      return () => clearInterval(intervalId);
    }
  }, [carouselIndex]);

  return (
    <Background
      backgroundColor={backgroundColor as string}
      $superposition={
        superpositionOfNextSection === null
          ? true
          : (superpositionOfNextSection as boolean)
      }
      $extraSpaceOnTop={extraSpaceOnTop}
    >
      <Wrapper>
        <Container
          align={"left"}
          extraPaddingOnBottom={extraPaddingBottom === false}
        >
          {sectionTitle?.childMarkdownRemark?.rawMarkdownBody && (
            <WebTitle
              size="xxl"
              as="h1"
              color={titleColor === "black" ? "primary" : "branding-secondary"}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {sectionTitle.childMarkdownRemark.rawMarkdownBody}
            </WebTitle>
          )}
          {description?.childMarkdownRemark?.html && (
            <TextContent
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
              style={{ whiteSpace: "pre-wrap" }}
            />
          )}
          {_arguments && (
            <ArgumentList
              align={"left"}
              inline={argumentsLayout === "one line"}
            >
              {_arguments.map((argument: any, index: number) => (
                <Argument align={"left"} color="branding" key={index}>
                  <Icon size={24} name="check-small" color="branding" />
                  <WebText
                    size="m"
                    color="branding"
                    style={{ fontWeight: "bold" }}
                  >
                    {argument}
                  </WebText>
                </Argument>
              ))}
            </ArgumentList>
          )}
          {(button || price) && (
            <PriceButtonContainer>
              <InlineContainer align={"left"}>
                {price && displayPrice && (
                  <PriceElement
                    price={price}
                    textBeforePrice={textBeforePrice}
                  />
                )}
                {button && (
                  <ButtonElement link={button} size="l" color={color}>
                    {button.label}
                  </ButtonElement>
                )}
                {secondaryButton && (
                  <LinkRenderer link={secondaryButton}>
                    <SecondaryButton color={secondaryButtonColor as string}>
                      {secondaryButton.label}
                    </SecondaryButton>
                  </LinkRenderer>
                )}
              </InlineContainer>
            </PriceButtonContainer>
          )}
        </Container>
        {image && !carrouselImages && (
          <ImageWrapper backgroundColor={backgroundColor as string}>
            {image?.gatsbyImageData && videoUrl && (
              <VideoHeroContainer
                videoUrl={videoUrl}
                $centeredOnMobile={centeredImageOnMobile as boolean}
              >
                <ImageElement
                  gatsbyImageData={image?.gatsbyImageData}
                  alt={image?.title || ""}
                />
              </VideoHeroContainer>
            )}
            {image?.gatsbyImageData && !videoUrl && (
              <ImageHeroContainer
                $centeredOnMobile={centeredImageOnMobile as boolean}
              >
                <ImageElement
                  gatsbyImageData={image?.gatsbyImageData}
                  alt={image?.title || ""}
                />
              </ImageHeroContainer>
            )}
          </ImageWrapper>
        )}
        {carrouselImages && (
          <CarouselContainer
            ref={carouselRef}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            {onMobileOrTablet || carouselIndex === 0 ? null : (
              <CarouselButton right={false} onClick={goToPrevSlide}>
                <Icon size={32} name="chevron-left" color="primary" />
              </CarouselButton>
            )}
            <CarouselContent>
              {carrouselImages.map((item: any, i: number) => {
                return (
                  <LinkRenderer link={carrouselLinks?.[i]}>
                    <CarouselItem key={`carouselItem${i}`}>
                      <CarouselMainImageContainer
                        style={{
                          transform: `translateX(${
                            100 - carouselIndex * 100
                          }%)`,
                        }}
                      >
                        <ImageElement
                          gatsbyImageData={item?.gatsbyImageData}
                          alt={item?.title || ""}
                          style={{ width: "50%" }}
                        />
                      </CarouselMainImageContainer>
                      <CarouselGraphImageContainer
                        style={{
                          transform: `translateX(${
                            300 - carouselIndex * 300
                          }%)`,
                        }}
                      >
                        <ImageElement
                          gatsbyImageData={
                            carrouselSecondaryImages?.[i]?.gatsbyImageData
                          }
                          alt={carrouselSecondaryImages?.[i]?.title}
                        />
                      </CarouselGraphImageContainer>
                      <CarouselIconImageContainer
                        style={{
                          transform: `translateX(${
                            400 - carouselIndex * 400
                          }%)`,
                        }}
                      >
                        <ImageElement
                          gatsbyImageData={carrouselIcons?.[i]?.gatsbyImageData}
                          alt={carrouselIcons?.[i]?.title}
                        />
                      </CarouselIconImageContainer>
                      <CarouselTextContainer
                        style={{
                          transform: `translateX(${
                            200 - carouselIndex * 200
                          }%)`,
                        }}
                      >
                        {carrouselImagesSubtexts?.[i]}
                      </CarouselTextContainer>
                    </CarouselItem>
                  </LinkRenderer>
                );
              })}
            </CarouselContent>
            {onMobileOrTablet ||
            carouselIndex === carrouselImages.length - 1 ? null : (
              <CarouselButton right={true} onClick={goToNextSlide}>
                <Icon size={32} name="chevron-right" color="primary" />
              </CarouselButton>
            )}
          </CarouselContainer>
        )}
      </Wrapper>
    </Background>
  );
};

export default SectionHeroImage;

export const fragment = graphql`
  fragment SectionHeroImageFragment on ContentfulSectionHeroImage {
    sectionTitle: title {
      childMarkdownRemark {
        rawMarkdownBody
      }
    }
    titleColor
    description {
      childMarkdownRemark {
        html
      }
    }
    image {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      title
    }
    videoUrl
    backgroundColor
    button {
      ...LinkFragment
    }
    buttonColor
    textArguments: arguments
    argumentsLayout
    price
    textBeforePrice
    superpositionOfNextSection
    centeredImageOnMobile
    showPrice
    secondaryButton {
      ...LinkFragment
    }
    secondaryButtonColor
    carrouselImages {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      title
    }
    carrouselImagesSubtexts
    carrouselSecondaryImages {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      title
    }
    carrouselLinks {
      ...LinkFragment
    }
    carrouselIcons {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      title
    }
    extraSpaceOnTop
    extraPaddingBottom
  }
`;

type BackgroundProps = {
  backgroundColor: string;
  $superposition: boolean;
  $extraSpaceOnTop: boolean;
};

const Background = styled.div<BackgroundProps>`
  background: ${color("background-primary:10")};
  ${(props) =>
    (props.backgroundColor === "blue gradient" ||
      props.backgroundColor === "blue gradient and wave") &&
    css`
      ${media.greaterThan("tablet")`
        background: url(${BackgroundImage});
        background-size: cover;
      `};
    `};
  ${media.greaterThan("tablet")`
        ${(props: any) =>
          props.$superposition &&
          css`
            margin-bottom: -64px;
          `}
        ${(props: any) =>
          props.$extraSpaceOnTop &&
          css`
            padding-top: 50px;
          `}
  `}
`;

const Wrapper = styled.div`
  ${containerCss}
  z-index: 1;
  position: relative;
  text-align: center;
  ${media.greaterThan("tablet")`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `}
`;

type SecondaryButtonProps = {
  color: string;
};
const SecondaryButton = styled.button<SecondaryButtonProps>`
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.2);
  font: inherit;
  font-weight: bold;
  color: ${color("branding-secondary")};
  border-radius: 5px;
  :hover {
    cursor: pointer;
  }
  ${(props) => {
    return props.color === "white"
      ? css`
          background-color: white;
          border: solid ${color("branding-secondary")} 1px;
          padding: 15px;
        `
      : css`
          background-color: ${color("branding-secondary:10")};
          padding: 17px;
        `;
  }}
`;

type ContainerProps = {
  align: "left";
  extraPaddingOnBottom: boolean;
};

const Container = styled.div<ContainerProps>`
  * {
    z-index: 5;
  }
  padding: 0 1rem;
  margin: 0 auto;
  h1 {
    padding-bottom: 0.5rem;
  }
  padding: 96px 1rem 0 1rem;
  ${media.greaterThan("tablet")`
    margin: auto;
    padding: 128px 2rem 11rem 2rem;
    text-align: center;
    h1 {
      padding-bottom: 1rem;
    }
    ${(props: any) =>
      props.align === "left" &&
      css`
        text-align: left;
        margin: 0;
      `};
    ${(props: any) =>
      props.extraPaddingOnBottom &&
      css`
        padding-bottom: 2rem;
        padding-top: 90px;
      `};
  `}
`;

const PriceButtonContainer = styled.div`
  margin-bottom: 1rem;
  ${media.greaterThan("tablet")`
    margin-bottom: 0;
    justify-content: ${(props: any) =>
      props.align === "left" ? "flex-start" : "center"};
      margin-top: 1.5rem;
  `}
`;

type ArgumentListProps = {
  inline: boolean;
  align?: "left";
};

export const ArgumentList = styled.div<ArgumentListProps>`
  margin: auto auto 0.75rem auto;
  display: flex;
  flex-direction: ${(props) => (props.inline ? "row" : "column")};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${media.greaterThan("tablet")`
    display: flex;
    flex-direction: ${(props: any) => (props.inline ? "row" : "column")};
    align-items: ${(props: any) => (props.inline ? "center" : "flex-start")};
    justify-content: ${(props: any) =>
      props.align === "left" ? "flex-start" : "center"};
    margin: auto;
  `}
`;

type ArgumentProps = {
  align: "left";
};

export const Argument = styled.div<ArgumentProps>`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.75rem;
  margin: 0 0.25rem;
  justify-content: center;

  ${media.greaterThan("tablet")`
   ${(props: any) =>
     props.align === "left" &&
     css`
       margin: 0 0.5rem 0 0;
     `};
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 0;
  `};
`;
const TextContent = styled.div`
  p {
    margin-bottom: 12px;
    color: ${color("text-primary:75")};
    ${webtext("text", "l")};
    position: relative;
  }
`;

type ImageWrapperProps = {
  backgroundColor: string;
};

const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  height: 380px;
  ${(props) =>
    props.backgroundColor === "blue gradient and wave" &&
    css`
      background: url(${BackgroundImageMobile});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
    `};
  ${media.greaterThan("tablet")`
    height: 100%;
    position: initial;
 `};
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const CarouselContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CarouselItem = styled.div`
  position: relative;
  width: 600px;
  height: 600px;
  ${media.lessThan("tablet")`
    width: 500px;
    height: 500px;
 `};
`;

const CarouselMainImageContainer = styled.div`
  transition: 2s ease;
  width: 600px;
  position: absolute;
  top: 100px;
  left: 150px;
  z-index: 1;
  ${media.lessThan("tablet")`
  top: 50px;
    width: 500px;
    left: 130px;
 `};
`;

const CarouselGraphImageContainer = styled.div`
  transition: 3s ease;
  width: 200px;
  position: absolute;
  z-index: 3;
  left: 100px;
  top: 350px;
  ${media.lessThan("tablet")`
    width: 170px;
    top: 260px;
 `};
`;

const CarouselIconImageContainer = styled.div`
  transition: 2.5s ease;
  width: 150px;
  position: absolute;
  z-index: 2;
  top: 260px;
  left: 90px;
  ${media.lessThan("tablet")`
    width: 125px;
    top: 190px;
    left: 100px;
 `};
`;

const CarouselTextContainer = styled.p`
  transition: 2.5s ease;
  position: absolute;
  width: 300px;
  top: 500px;
  left: 150px;
  z-index: 3;
  ${media.lessThan("tablet")`
    width: 250px;
    left: 130px;
    top: 390px;
 `};
`;

type CarouselButtonProps = {
  right: boolean;
};

const CarouselButton = styled.button<CarouselButtonProps>`
  z-index: 10;
  position: absolute;
  top: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: 25px;
  color: ${color("branding-secondary")};
  background-color: white;
  opacity: 0.5;
  border-radius: 50%;
  :hover {
    cursor: pointer;
  }
  ${(props) =>
    props.right === true
      ? css`
          right: 2%;
        `
      : css`
          left: 2%;
        `}
`;
